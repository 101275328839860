<template>
  <div class="page">
    <section>
      <header>
        <img
          @click="$router.push({ path: '/' })"
          class="logo"
          src="@image/logo.png"
          alt="logo"
        />
        <span @click="$router.push({ path: '/' })" class="title">网医</span>
        <div class="goToLogin">
          <span>已有帐号，</span>
          <span
            class="login"
            @click="bus.$emit('method', { method: 'tabShowLogin', options: true })"
            >马上登录</span
          >
        </div>
      </header>
      <div class="form">
        <header>
          <span>欢迎注册网医</span>
        </header>
        <section>
          <el-input
            autocomplete="off"
            placeholder="请输入姓名"
            v-model="from.nickname"
            prefix-icon="el-icon-user"
          >
            <!-- suffix-icon="el-icon-user" -->
          </el-input>
          <!-- <el-input
            placeholder="请输入所属卫生机构全称"
            v-model="from.unit"
            prefix-icon="el-icon-first-aid-kit"
          >
          </el-input>
          <el-input
            placeholder="请输入身份证号码"
            maxlength="18"
            v-model="from.id_card"
            prefix-icon="el-icon-postcard"
          >
          </el-input> -->
          <el-input
            placeholder="请输入手机号"
            maxlength="11"
            type="tel"
            v-model="from.phone"
            prefix-icon="el-icon-mobile-phone"
            autocomplete="off"
          >
          </el-input>
          <el-input
            autocomplete="off"
            class="code"
            placeholder="请输入验证码"
            maxlength="6"
            v-model="from.code"
            prefix-icon="el-icon-message"
          >
            <template type="4" slot="append">
              <div v-if="isCode" @click="setPhoneCode">获取验证码</div>
              <div v-else>{{ codeNum }}秒后重试</div>
            </template>
          </el-input>
          <el-tooltip
            class="item"
            effect="light"
            content="密码8-24位,至少包括字母,大小写,数字,特殊字符（!@#$%^&*_-）"
            placement="top-start"
          >
            <el-input
              placeholder="请输入密码（8-24位）"
              type="password"
              v-model="from.password"
              prefix-icon="el-icon-lock"
              @blur="passwordVerify()"
              autocomplete="new-password"
            >
            </el-input>
          </el-tooltip>
          <el-input
            autocomplete="new-password"
            placeholder="请再次确认密码"
            type="password"
            v-model="from.repassword"
            prefix-icon="el-icon-lock"
          >
          </el-input>

          <el-button round @click="regBtn">注册</el-button>
          <div style="margin-top: 30px">
            <el-checkbox class="readAndAgree" v-model="readAndAgree"
              >阅读并同意
            </el-checkbox>
            <span
              class="lan-a"
              @click="
                xin.goUrl('/setting?index=2');
                showLogin = false;
              "
              >《用户协议》</span
            >
            <span
              class="lan-a"
              @click="
                xin.goUrl('/setting?index=3');
                showLogin = false;
              "
              >《隐私声明》</span
            >
          </div>
        </section>
      </div>
    </section>

    <el-dialog
      width="560px"
      style="position: absolute; top: 15vh"
      class="cancellation"
      custom-class="opinion"
      :visible.sync="showModalCancellationSuccess"
    >
      <div class="form chengForm" style="height: 420px">
        <img
          @click="cancellationCloseFun"
          src="../../assets/image/close.png"
          class="cancellationClose"
        />
        <div class="registerSuccess">注册成功</div>
        <img
          src="../../assets/image/registersuccess.png"
          class="registersuccessImg"
        />
        <div class="perfect">完善认证信息，体验更多服务</div>
        <div class="cancellationButtonDiv">
          <button @click="skip">跳过</button>
          <button @click="goAuthentication">去认证</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store";
import Kefu from "@components/common/kefu";
import wxlogin from "vue-wxlogin";
export default {
  data() {
    return {
      showModalCancellationSuccess: false,
      userAgreement: "",
      privacyStatement: "",
      isCode: true,
      codeNum: 60,
      count: -1,
      readAndAgree: false,
      provinceList: [],
      cityList: [],
      areaList: [],

      from: {
        nickname: "",
        // unit: "",
        // id_card: "",
        phone: "",
        code: "",
        password: "",
        repassword: "",

        // area: "",
        // province: "",
        // city: "",
      },
    };
  },
  created() {
    this.verify_version();
  },
  async mounted() {
    this.get_province();
  },
  methods: {
    ...mapActions("userManagement", [
      "saveLogin",
      "logout",
      "getUserInfo",
      "getPersonal",
    ]),
    async skip() {
      let from = {
        username: "",
        password: "",
      };

      from.username = this.from.phone;
      from.password = this.from.password;

      var that = this;
      if (await this.saveLogin(from)) {
        that.xin.goUrl("/");

        that.showLogin = false;
      }
      this.$loading.hide();
    },
    async goAuthentication() {
      let from = {
        username: "",
        password: "",
      };

      from.username = this.from.phone;
      from.password = this.from.password;

      var that = this;
      if (await this.saveLogin(from)) {
        that.xin.goUrl("/authentication");
        that.showLogin = false;
      }
      this.$loading.hide();
    },
    cancellationCloseFun() {
      this.showModalCancellationSuccess = false;
    },
    async verify_version() {
      let res = await this.$loginApi.verify_version({});
      if (res.code == 200) {
        this.userAgreement = new Date(
          parseInt(res.data.user_information.update_time) * 1000
        )
          .toLocaleString()
          .replace(/:\d{1,2}$/, " ");
        this.privacyStatement = new Date(
          parseInt(res.data.privacy_information.update_time) * 1000
        )
          .toLocaleString()
          .replace(/:\d{1,2}$/, " ");
      }
    },

    // 密码校验
    passwordVerify() {
      let reg =
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*_-])[0-9a-zA-Z!@#$%^&*_-]{8,24}$/;
      if (!reg.test(this.from.password)) {
        this.$message.error("密码格式有误");
        this.from.password = "";
      }
    },

    //省市区
    async get_province() {
      let { code, data } = await this.$loginApi.get_province();

      if (code == 200) {
        this.provinceList = data;
      }
    },
    async provinceChange(e) {
      let res = await this.$loginApi.get_city({
        id: e,
      });
      if (res.code == 200) {
        this.cityList = res.data;
      }
    },
    async cityChange(e) {
      let res = await this.$loginApi.get_area({
        id: e,
      });
      if (res.code == 200) {
        this.areaList = res.data;
      }
    },
    setPhoneCode() {
      if (!this.from.phone) {
        this.$message.error("请输入您的手机号");
        return;
      }
      this.$loading.show();
      this.$loginApi
        .massCode({
          phone: this.from.phone,
          type: 4,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("验证码已发送");
            this.isCode = false;
            this.codeNum = 60;
            let count = setInterval(() => {
              this.codeNum--;
              if (this.codeNum <= 0) {
                this.isCode = true;
                clearInterval(count);
              }
            }, 1000);
          } else {
            this.$message({ message: res.message, type: "error" });
          }
        })
        .catch((err) => err)
        .finally(() => this.$loading.hide());
    },
    regBtn() {
      if (!this.from.nickname) {
        this.$message.error("请输入姓名");
        return;
      }

      const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
      if (!this.from.phone) {
        this.$message.error("请输入手机号");
        return;
      }
      if (!reg.test(this.from.phone)) {
        this.$message.error("手机号格式有误");
        return;
      }
      if (!this.from.code) {
        this.$message.error("请输入验证码");
        return;
      }
      if (!this.from.password) {
        this.$message.error("请输入密码");
        return;
      }
      if (this.from.password.length < 6) {
        this.$message.error("请输入密码（6-20位）");
        return;
      }
      if (this.from.password != this.from.repassword) {
        this.$message.error("密码输入不一致");
        return;
      }
      // if (!this.from.province) {
      // 	this.$message.error("请选择省份");
      // 	return;
      // }
      // if (!this.from.city) {
      // 	this.$message.error("请选择城市");
      // 	return;
      // }
      // if (!this.from.area) {
      // 	this.$message.error("请选择区县");
      // 	return;
      // }

      if (!this.readAndAgree) {
        this.$message.error("请先阅读勾选用户隐私及服务协议");
        return;
      }

      this.$loading.show();
      this.$loginApi
        .register_user(this.from)
        .then((res) => {
          if (res.code === 200) {
            // this.$message.success("注册成功");
            this.showModalCancellationSuccess = true;
            // this.$emit("method", {
            //   method: "tabShowLogin",
            //   options: true,
            // });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => err)
        .finally(() => this.$loading.hide());
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  background-color: #f4f4f4;

  > section {
    display: flex;
    flex-direction: column;

    > header {
      height: 176px;
      display: flex;
      align-items: center;

      > .logo {
        width: 56px;
        height: 56px;
        cursor: pointer;
      }

      > .title {
        font-size: 30px;
        margin-left: 10px;
        cursor: pointer;
      }

      > .goToLogin {
        margin-left: auto;
        display: flex;
        font-size: 24px;
        color: #333333;

        > .login {
          color: $--themeColor;
          cursor: pointer;
        }
      }
    }

    > .form {
      /deep/.el-input--prefix {
        color: #8f8f8f;
        background: #f2f2f2;
        border-radius: 44px;

        .el-input__inner {
          border: none;
          background: none;
        }
      }

      > header {
        height: 80px;
        background-color: $--themeColor;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: white;
      }

      > section {
        background-color: white;
        padding: 30px 0 50px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .el-input {
          width: 360px;
          margin-top: 20px;

          // margin: 0 auto;
          &:first-child {
            margin-top: 0;
          }

          ::v-deep .el-input-group__append {
            background: none;
            border: none;
            color: $--themeColor;
            cursor: pointer;
          }

          ::v-deep .el-input__suffix {
            position: absolute;
            left: -57px;
            right: calc(100% + 32px);
            color: $--themeColor;
            font-size: 20px;
          }

          ::v-deep .el-input__prefix {
            font-size: 16px;
          }

          &.code {
            ::v-deep .el-input__suffix {
              position: absolute;
              left: calc(-57px + 110px);
              right: calc(100% + 32px);
              transition: 0s;
            }
          }
        }

        > .el-button {
          margin-top: 20px;
          width: 360px;
          height: 44px;
          border-radius: 44px;
          border: none;
          background-color: $--themeColor;
          color: white;
          font-size: 16px;
        }

        > .readAndAgree {
          margin: 20px auto 0;
        }
      }
    }
  }
}

.province {
  width: 360px;
  margin-top: 20px;
}
.chengSpan {
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 15px;
  color: #8f8f8f;
  opacity: 1;
  margin-top: 6px;
}

.cancellation {
  position: relative;
}

.cancellationClose {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 25px;
  top: 25px;
}

/deep/.el-dialog__body {
  &::before {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background-color: $--themeColor;
  }
}

.registerSuccess {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 40px;
  color: #333333;
  opacity: 1;
  margin: 0 auto;
  text-align: center;
  padding-top: 38px;
}

.registersuccessImg {
  width: 147px;
  height: 122px;
  margin-top: 30px;
}

.perfect {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  // line-height: 50px;
  color: #333333;
  opacity: 1;

  margin-top: 30px;
}

.cancellationButtonDiv {
  margin: 0 auto;
  width: 380px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.cancellationButtonDiv button:nth-child(1) {
  width: 170px;
  height: 50px;
  background: #ffffff;
  border: 2px solid #03dbc6;
  opacity: 1;
  border-radius: 2000px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 50px;
  color: #03dbc6;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cancellationButtonDiv button:nth-child(2) {
  width: 170px;
  height: 50px;
  background: #03dbc6;
  border: 1px solid #03dbc6;
  opacity: 1;
  border-radius: 2000px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 50px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/deep/ {
  .opinion {
    position: relative;

    .el-icon-close {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 20px;
      cursor: pointer;
      color: #333;
    }

    border-radius: 20px;

    .el-dialog__body {
      padding: 0;
      color: #333;
      font-weight: 400;

      .title {
        font-weight: 400;
        text-align: left;
        color: #333333;
        font-size: 24px;
      }

      .input-name {
        margin: 30px 0 20px 0;

        span {
          color: #8f8f8f;
        }
      }

      textarea {
        padding: 10px 20px;
        min-height: 100px;
        border-radius: 10px;
        background: #f2f2f2;
        border: none;
        font-size: 16px;
        width: 700px;
        min-width: 700px;
        max-width: 700px;
        max-height: 800px;
        box-sizing: border-box;
      }

      .images-box {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 30px;
        border-bottom: #f2f2f2 1px solid;

        .imgs {
          position: relative;
          width: 150px;
          height: 150px;
          border-radius: 5px;
          margin-top: 22px;
          margin-right: 32px;

          &:nth-child(4n) {
            margin-right: 0;
          }

          &:hover .rowp {
            opacity: 1;
            // transition: all 1s linear;
          }

          .rowp {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background: #0005;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            color: #fff;
            opacity: 0;
            transition: opacity 0.3s;
            border-radius: 5px;

            span {
              padding: 5px;
              font-size: 24px;
              cursor: pointer;
            }
          }

          img {
            border-radius: 5px;
            display: block;
            width: 150px;
            height: 150px;
            object-fit: cover;
          }
        }

        .add-img {
          margin-top: 22px;
          display: flex;
          cursor: pointer;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 150px;
          height: 150px;
          color: #bbb8b8;
          border: 1px solid #d9d9d9;
          border-radius: 5px;

          input {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            opacity: 0;
            cursor: pointer;
          }

          .el-icon-plus {
            margin-bottom: 10px;
            font-size: 50px;
          }
        }
      }

      .input-phone {
        background: #f2f2f2;
        height: 32px;
        border-radius: 10px;
        padding: 10px 20px;

        input {
          font-size: 16px;
          height: 100%;
          width: 100%;
          background: none;
          outline: none;
          border: none;
        }
      }

      .btns {
        margin-top: 50px;
        display: flex;
        justify-content: space-evenly;

        .el-button--default {
          border: $--themeColor 1px solid;
          color: $--themeColor;
        }

        .el-button {
          width: 170px;
          height: 50px;
          border-radius: 26px;
          font-size: 18px;
        }
      }
    }
  }
}
.chengForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
</style>
